import Axios from "axios";

export default {
    init() {
        let chAdmins = document.getElementsByClassName('js-user-change');
        for(let i = 0; i < chAdmins.length; i++) {
            chAdmins[i].addEventListener('change', (event) => {

                let data = {
                    id: chAdmins[i].dataset.id,
                    fields: {}
                };
                console.log(chAdmins[i].dataset.role);
                data.fields[chAdmins[i].dataset.role] = event.srcElement.checked;

                Axios.post('/api/user/edit', data).then((res) => {
                    if(res.data.error)
                        alert(res.data.errorText);
                });
            });
        }
    }
}