import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import {transform} from 'ol/proj';
import Point from 'ol/geom/Point';
import {Feature} from "ol";
import VectorSource from 'ol/source/Vector';
import VectorLayer from "ol/layer/Vector";
import {Fill, Style, Circle, Text} from "ol/style";
import {defaults as defaultInteractions, DoubleClickZoom, MouseWheelZoom, DragPan} from 'ol/interaction';
import Axios from "axios";

import "ol/ol.css";
import th from "element-ui/src/locale/lang/th";

export default {
    init() {

        let activeClass = 'theaters_filter_item--active';
        let festivalsBtn = document.getElementById('select-festivals');
        let theatresBtn = document.getElementById('select-theatres');

        if(festivalsBtn) {
            festivalsBtn.addEventListener('click', () => {
                this.changeFeatures('festivals');
                theatresBtn.classList.remove(activeClass);
                festivalsBtn.classList.add(activeClass);
            });
        }

        if(theatresBtn) {
            theatresBtn.addEventListener('click', () => {
                this.changeFeatures('theatres');
                festivalsBtn.classList.remove(activeClass);
                theatresBtn.classList.add(activeClass);
            });
        }

        this.map();
    },

    /**
     * Показать фестивали
     */
    changeFeatures(items) {
        this.vectorSource.clear();
        Axios.get(`/api/handbooks/${items}`)
            .then((res) => {
                this.theatres = res.data;
                let data = res.data;
                let type = items === 'theatres' ? 'theatre' : 'festival';
                for(let i = 0; i < data.length; i++) {
                    if(data[i].points) {
                        data[i].rowSpan = Number.parseInt(data[i].rowSpan);
                        this.vectorSource.addFeature(this.feature(data[i].points, data[i].city, data[i].id, type, data[i].rowSpan, data[i].city));
                    }
                }
            });
    },

    /**
     * Слой с метками
     */
    vectorSource: {},

    /**
     * Список театров на карте
     */
    theatresMap: {},

    /**
     * Список театров
     */
    theatres: [],

    /**
     * Генерация карты
     */
    map() {

        this.vectorSource = new VectorSource();
        this.changeFeatures('theatres');

        let vectorLayer = new VectorLayer({
            source: this.vectorSource
        });

        // область просмотра
        let view = new View({
            center: this.transform(42.570626, 12.651037),
            zoom: 5.95,
            rotation: 6.5
        });

        // запрет зума
        let interactions = defaultInteractions();
        interactions.forEach((interaction, idx) => {
            // отключаем зумирование по двойному клику
            if (interaction instanceof DoubleClickZoom)
                interaction.handleEvent = function() {return;};

            // интерекшн для зумирования чтобы инвертировать скролл и отменять зумирование при следовании
            if (interaction instanceof MouseWheelZoom)
                interaction.handleEvent = function() {return;};

            // запоминаем интерэкшн перетаскивания чтобы при включении следования выключать возможность перетаскивания
            if (interaction instanceof DragPan)
                interaction.setActive(false);
        });

        // карта
        let map = new Map({
            interactions: interactions,
            target: 'map',
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'http://mapsserver.regatav1.ru/mbtiles/political_map/{z}/{x}/{y}'
                    })
                }),
                vectorLayer
            ],
            controls: [],
            view: view
        });

        // клик по карте
        map.on('click', (evt) => {

            // если на карте сейчас есть элемент со списокм театров
            // то его нужно удалить
            if(this.theatresMap.nodeName) {
                this.theatresMap.remove();
            }

            let feature = map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
                    return feature;
                },
                {
                    layerFilter: (layer) => {
                        return layer === vectorLayer;
                    }
                });

            // клик был по маркеру
            if (feature) {
                let rowSpan = feature.get('rowSpan');
                if(!rowSpan) {
                    this.toTheatre(feature.get('theatreID'), feature.get('type'));
                }
                else {

                    let geometry = feature.getGeometry();
                    let coordinate = geometry.getCoordinates();
                    let pixel = map.getPixelFromCoordinate(coordinate);

                    this.theatresMap = document.createElement('div');
                    this.theatresMap.classList.add('theatre-map_wrapper');

                    let list = this.cityList(feature.get('city'));

                    for(let i = 0; i < list.length; i++) {
                        let item = document.createElement('div');
                        item.classList.add('theatre-map_item');
                        item.textContent = list[i].name;
                        item.addEventListener('click', () => {
                           this.toTheatre(list[i].id, 'theatre');
                        });
                        this.theatresMap.appendChild(item);
                    }

                    let mapDiv = document.getElementById('map');
                    mapDiv.appendChild(this.theatresMap);
                    this.theatresMap.style.margin = `${pixel[1]}px 0  0 ${pixel[0]}px`;
                }
            }
        });
    },

    /**
     * Список театров с одинаковым городом
     */
    cityList(city) {

        let list = [];

        for(let i = 0; i < this.theatres.length; i++) {
            if(this.theatres[i].city === city) {
                list.push(this.theatres[i]);
            }
        }

        return list;
    },

    /**
     * Перевод координат
     * @param lon
     * @param lat
     * @return {*}
     */
    transform(lon, lat) {
        return transform([lat, lon], 'EPSG:4326', 'EPSG:3857');
    },

    /**
     * Формирование маркера
     * @param coordinate
     * @param text
     * @param theatreID
     * @param type
     * @param rowSpan
     * @param city
     */
    feature(coordinate, text = '', theatreID = 0, type = 'theatre', rowSpan = 0, city = '') {

        let fill = new Fill({
            color: 'black'
        });

        let style = new Style({
            image: new Circle({
                fill: fill,
                radius: 4
            }),
            text: new Text({
                font: '14px Calibri,sans-serif',
                offsetY: 11,
                text: text
            })
        });

        let p = coordinate.split(',');

        // добавление координат
        let points = new Point(this.transform(p[0], p[1]));
        let feature = new Feature(points);
        feature.setStyle(style);
        feature.set('theatreID', theatreID);
        feature.set('type', type);
        feature.set('rowSpan', rowSpan);
        feature.set('city', city);
        return feature;
    },

    /**
     * Переход на страницу театра
     * @param id
     * @param type
     */
    toTheatre(id, type) {
        location.href = `/handbooks/${type}/${id}`;
    }
}