export default {
    init() {

        // переключение
        let descriptionBtn = document.getElementById('program-description');
        let eventsBtn = document.getElementById('program-events');
        let activeClass = 'theaters_filter_item--active';

        if(descriptionBtn && eventsBtn) {
            let description = document.getElementById('description');
            let events = document.getElementById('events');

            descriptionBtn.addEventListener('click', () => {
                events.style.display = 'none';
                description.style.display = 'block';
                descriptionBtn.classList.add(activeClass);
                eventsBtn.classList.remove(activeClass)
            });
            eventsBtn.addEventListener('click', () => {
                events.style.display = 'block';
                description.style.display = 'none';
                descriptionBtn.classList.remove(activeClass);
                eventsBtn.classList.add(activeClass)
            });
        }

        $(document).ready(function() {
            $(".js-gallery").fancybox({
                openEffect	: 'none',
                closeEffect	: 'none'
            });
        });
    }
}