import Axios from "axios";

export default {

    /**
     * Параметры плеера
     */
    playerParams: {
        simple : true,
        fullScreen: true,
        autoplay: true,
        src: '',
        groups: [],
        playlistView: true,
        startFile: 1,
        downloadLink: '',
        saveTime: true
    },

    /**
     * Инициализация
     */
    init() {

        let playRow = $('.js-play-film');

        playRow.click((event) => {
            let row = $(event.currentTarget).closest('tr');
            let btn = $(event.currentTarget);

            $('tr').removeClass('selected-tr');
            $(row).addClass('selected-tr');

            let playList = {
                title: 'title',
                src: []
            };

            Axios.get(`/api/film/${row.data('id')}/files`)
                .then((res) => {
                    let films = res.data;

                    if(films.length) {

                        // заполнение плейлиста
                        for(let i = 0; i < films.length; i++) {
                            let number = i + 1;
                            playList.src.push({
                                link: films[i].url,
                                title: films[i].title,
                                videoTitle: films[i].title
                            });
                            this.playerParams.downloadLink = films[i].download;
                        }
                        this.playerParams.groups = [];
                        this.playerParams.groups.push(playList);

                        // отключить отображение плейлиста если файл только один
                        if(films.length === 1)
                            this.playerParams.playlistView = false;
                        else
                            this.playerParams.playlistView = true;

                        if(!btn.data('index')) {
                            this.playerParams.startFile = 0;
                        }
                        else {
                            this.playerParams.startFile = btn.data('index');
                        }

                        row.find('.new-item').remove();

                        console.log(this.playerParams);

                        // запуск плеера
                        this.startPlayer();
                    }
                })
                .catch((e) => {
                   console.log(e);
                });
        });
    },

    /**
     * Запуск плеера
     */
    startPlayer() {
        console.log(this.playerParams);
        this.createDomPlayer().videoplayer(this.playerParams);
    },

    /**
     * Создать плеер в DOM
     */
    createDomPlayer() {

        let video = document.createElement('video');
        video.setAttribute('preload', 'auto');

        let videoWrapper = document.createElement('div');
        videoWrapper.classList.add('video-modal');
        videoWrapper.classList.add('video_outer');

        videoWrapper.appendChild(video);
        document.body.appendChild(videoWrapper);

        return $(video);
    }
}