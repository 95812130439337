class PageHistory {
    static init() {

        // let hash = 'aHR0cHM6Ly9lcmx5dmlkZW8udjYuc3BiLnJ1L0JBVFRMRS92ZXJkaS9jb21wb3Nlci9maWxtcy8zNC81ZThiMDYyY2JiMGFiLm1wNA==';

        PageHistory.lastUrl = localStorage.getItem('lastPage');
        localStorage.setItem('lastPage', window.location.pathname);

        let hash = localStorage.getItem('V6Player-time');
        if(hash) {
            hash = JSON.parse(hash);

            for(let key in hash) {
                console.log(key);
                $(document).ready(() => {
                    let file = $(`[data-file-hash="${key}"]`);
                    console.log(file.first());
                    file.first().click();
                });
            }
        }

        let btn = document.getElementById('last-page');
        if(btn) {
            btn.addEventListener('click', this.loadLast);
            if(PageHistory.lastUrl)
                btn.style.display = 'inline-block';
        }
    }

    /**
     * Переход страницу из Истории
     */
    static loadLast() {
        location.href = PageHistory.lastUrl;
    }
}

PageHistory.lastUrl = '';

export default PageHistory;