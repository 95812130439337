import '../css/main.scss';
import Views from "./Modules/Views";
import Default from "./Modules/Default";
import Film from "./Modules/Film";
import Map from "./Modules/Map";
import Theatre from "./Modules/Theatre";
import Users from "./Modules/Users";
import HistoryFilm from "./Modules/HistoryFilm";
import PageHistory from "./Modules/PageHistory";
/*
import $ from 'jquery';

global.jQuery = $;
global.$ = $;
 */

console.log ('main');

Default.init();
Views.init();
Film.init();
Map.init();
Theatre.init();
Users.init();
HistoryFilm.init();
PageHistory.init();