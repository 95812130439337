import HistoryFilm from "../../../Admin/Modules/HistoryFilm";

export default {
    init() {

        $(document).on('click', '.js-play-history', function () {
            let filmID = $(this).closest('tr').data('id');
            HistoryFilm.files(filmID, (files) => {
                let src = [];

                for (let key in files) {
                    if(files[key].converted) {
                        src.push({
                            title: files[key].title,
                            src: files[key].path + files[key].name
                        });
                    }
                }

                // создание плеера
                let videoWrapper = $('<div class="video-player bvideo"></div>');
                let video = $('<video></video>');
                videoWrapper.append(video);
                $('body').append(videoWrapper);

                let playerParams = {
                    simple : true,
                    fullScreen: true,
                    autoplay: true,
                    fullPanel: true,
                    playlistTitle: '',
                    autoSwitch: true,
                    src: src,
                    saveTime: true
                };

                video.videoplayer(playerParams, false);
            });
        });
    }
}