import {forEach} from "ol/geom/flat/segments";

export default {
    init() {
        this.resetContext();
        this.backBtn();
        this.link();
        this.linkHistory();
        this.returnHome();
        this.download();
    },

    /**
     * Запрет контекстного меню на всем сайте
     */
    resetContext() {
        document.oncontextmenu = () => {
            return false;
        };
    },

    /**
     * Кнопка назад
     */
    backBtn() {
        let btn = document.getElementById('btn-back');
        if(btn) {
            btn.addEventListener('click', () => {
                history.back();
            })
        }
    },

    /**
     * Кастоманя ссылка
     */
    link() {
        let links = document.getElementsByClassName('js-link');
        for(let i = 0; i < links.length; i++) {
            links[i].addEventListener('click', (event) => {
                console.log(event);
                let href = links[i].dataset.href;
                if(href)
                    location.href = href;
            });
        }
    },

    /**
     * Подсветка ссылок по истории
     */
    linkHistory() {
        let link = sessionStorage.getItem('prevLink');

        if(link) {
            let links = document.querySelectorAll('.js-prev-href[data-href="'+link+'"]');
            for(let i = 0; i < links.length; i++) {
                links[i].classList.add('prevLink');
                links[i].scrollIntoView({
                    block: "center"
                });
            }
        }

        sessionStorage.setItem('prevLink', document.location.pathname);
    },

    /**
     * Вернуться на главной и подсветить композитора
     */
    returnHome() {
        let btn = document.getElementById('return-home');
        if(btn) {
            btn.addEventListener('click', (event) => {
                if(event.cancelable)
                    event.preventDefault();
                let composerID = btn.dataset.composer;
                let link = `/composer/${composerID}/operas`;
                sessionStorage.setItem('prevLink', link);
                location.href = '/';
            });
        }
    },

    /**
     * Контекстное меню для скачивания
     */
    download() {
        let body = document.getElementsByTagName('body')[0];
        let downloads = document.getElementsByClassName('js-download');
        if(downloads.length) {

            let downloadBtn = document.createElement('a');
            downloadBtn.classList.add('context-download');
            downloadBtn.textContent = 'скачать';

            for(let i = 0; i < downloads.length; i++) {
                downloads[i].addEventListener('contextmenu', (event) => {
                    downloadBtn.style.left = event.clientX + 'px';
                    downloadBtn.style.top = event.clientY + 'px';
                    downloadBtn.href = downloads[i].dataset.file;
                    downloadBtn.download = downloads[i].dataset.fileName;

                    body.appendChild(downloadBtn);
                    document.addEventListener('click', () => {
                        downloadBtn.remove();
                    }, {once: true});
                    document.getElementsByClassName('table-fixer')[0].addEventListener('scroll', () => {
                        downloadBtn.remove();
                    }, {once: true});
                });
            }
        }
    }
}