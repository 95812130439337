import Axios from "axios";

export default {

    /**
     * Инициализация счетчика
     */
    init() {
        this.viewsCount();
    },

    /**
     * Счетчик просмотров
     */
    viewsCount() {
        let playerStartBtn = document.getElementsByClassName('js-start-play');

        for(let i = 0; i < playerStartBtn.length; i++) {
            playerStartBtn[i].addEventListener('click', event => {
                let id = playerStartBtn[i].dataset.id;
                let entity = playerStartBtn[i].dataset.entity;
                Axios.put(`/api/view/${entity}/${id}`);
            });
        }
    }
}