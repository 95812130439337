import Axios from "axios";

export default {

    /**
     * Список фильмов
     * @param success
     */
    list(success) {
        Axios.get('/api/historyfilms')
            .then((res) => {
                if(res.data.error)
                    console.log(res.data.errorText);
                else
                    success(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    },

    /**
     * Список файлов
     * @param filmID
     * @param success
     */
    files(filmID, success) {
        Axios.get(`/api/historyfilm/${filmID}/files`)
            .then((res) => {
                if(res.data.error)
                    console.log(res.data.errorText);
                else
                    success(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    },

    /**
     * Постановка
     * @param id
     * @param success
     */
    item(id, success) {
        Axios.get(`/api/historyfilm/${id}`)
            .then((res) => {
                if(res.data.error)
                    console.log(res.data.errorText);
                else
                    success(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    },

    /**
     * Редактировать симфонию
     * @param film
     * @param success
     */
    save(film, success) {

        let data = {
            id: film.id,
            fields: film
        };

        Axios.post('/api/historyfilm/save', data)
            .then((res) => {
                if(res.data.error)
                    console.error(res.data.error);
                else
                    success(res.data);
            })
            .catch((e) => {
                console.error(e);
            })
    }
}